import { Link, PageProps } from 'gatsby';
import React from 'react';

import AspectBox from '../../components/AspectBox';
import Img from '../../components/Img';
import Layout from '../../components/Layout';
import { buildImgixUrlWithAspectRatio } from '../../util/images';

import './Categories.scss';

export interface CategoriesPageContext {
  categories: {
    path: string;
    name: string;
    previewPhoto: string;
  }[];
}

const Categories: React.FC<PageProps<unknown, CategoriesPageContext>> = ({ pageContext: { categories } }) => (
  <Layout
    mainClassName="categories"
  >
    <div className="container">
      <ul>
        {categories.map((category) => (
          <li key={category.path}>
            <Link to={category.path}>
              <AspectBox heightRatio={1}>
                <div>
                  <Img src={buildImgixUrlWithAspectRatio(category.previewPhoto, 1, 300)} />
                </div>
              </AspectBox>

              <h1>{category.name}</h1>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </Layout>
);

export default Categories;
